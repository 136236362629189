.pagination-container {
    display: flex;
    list-style-type: none;
}

    .pagination-container .pagination-item {
        padding: 0 12px;
        height: 28px;
        text-align: center;
        margin: auto 4px;
        color: #141c4c;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.01071em;
        border-radius: 23px;
        line-height: 1.43;
        font-size: 16px;
    }

    .pagination-container .pagination-item.dots:hover {
            background-color: transparent;
            cursor: default;
        }

    .pagination-container .pagination-item:hover {
            background: #0c8ec7;
            border-radius: 4px;
            color: #151929;
            cursor: pointer;
        }

    .pagination-container .pagination-item.selected {
            background: #0c8ec7;
            border-radius: 4px;
            color: #151929;
        }

    .pagination-container .pagination-item .arrow::before {
                position: relative;
                content: '';
                display: inline-block;
                width: 0.4em;
                height: 0.4em;
                border-right: 0.12em solid #141c4c;
                border-top: 0.12em solid #141c4c;
            }

    .pagination-container .pagination-item .arrow.left {
                -webkit-transform: rotate(-135deg) translate(-50%);
                        transform: rotate(-135deg) translate(-50%);
            }

    .pagination-container .pagination-item .arrow.right {
                -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
            }

    .pagination-container .pagination-item.disabled {
            pointer-events: none;
        }

    .pagination-container .pagination-item.disabled .arrow::before {
                border-right: 0.12em solid rgb(213 207 207 / 43%);
                border-top: 0.12em solid rgb(213 207 207 / 43%);
            }

    .pagination-container .pagination-item.disabled:hover {
                background-color: transparent;
                cursor: default;
            }

    .pagination-container .arrow-icon:hover {
            height: 28px;
            border-radius: 14px;
            background: #0c8ec7;
            color: #151929;
        }

    .pagination-container .arrow-icon {
        color: #151929;
}
.pagination-bar {
    justify-content: center;
    margin: 20px 0;
}
.pagination-goto::before {
        position: absolute;
        content: '';
        height: 25px;
        width: 1px;
        left: -11px;
        background: #404050;
    }
.pagination-goto .pagination-input-sec::after {
            content: '';
            background-image: url('../../../assets/icons/lightArrows/Right2Light.svg');
            background-position: center;
            background-repeat: no-repeat;
            width: 10px;
            height: 10px;
            position: absolute;
            right: 10px;
        }
